<template>
  <Default>
    <div class="locales">
    <banner
      :fondo="companyBG"
      titulo="Locales"
      descripcion="Lista de Locales registrados"
    ></banner>

    <section class="main__content">
      <div class="card">
        <div class="card-header row no-gutters">
          <input
            type="search"
            placeholder="Buscar por ID o nombre"
            class="form-control search col-12 col-md-4 "
            v-model="query"
          />

          <router-link
            :to="{ name: 'locales-crear' }"
            class="btn btn-primary btn-add-asignacion btn-sm col-12 col-md-3 offset-md-5 mt-3 mt-md-0"
          >
            <i class="fas fa-plus mr-2"></i>
            Registrar nuevo Local
          </router-link>
        </div>

        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-custom" v-if="itemsFiltrados.length">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">
                    <span class="small">ID</span>
                  </th>
                  <th scope="col"></th>
                  <th scope="col">
                    <span class="small">Nombre</span>
                  </th>
                  <!-- <th scope="col">
                    <span class="small">Ubicación (D-P-D)</span>
                  </th> -->
                  <th scope="col">
                    <span class="small">Dirección</span>
                  </th>
                  <th scope="col">
                    <span class="small">Celular</span>
                  </th>
                  <th scope="col">
                    <span class="small">Email</span>
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in itemsFiltrados" :key="item.userId">
                  <td data-label="ID" scope="row">{{ item.localId }}</td>
                  <td data-label="Imagen" scope="row">
                    <img
                      :src="item.imagen"
                      :alt="item.nombres"
                      class="locales__image"
                    />
                  </td>
                  <td data-label="Nombre">
                    <p class="small mt-2 mb-1">
                      {{ item.nomLocal }}
                    </p>
                  </td>
                  <!-- <td>
                    <p class="small mt-2 mb-1">
                      {{ item.departamento }} - {{ item.provincia }} - {{ item.distrito }}
                    </p>
                  </td> -->
                  <td data-label="Dirección">
                    <p class="small mt-2 mb-1">
                      {{ item.direccion }}
                    </p>
                  </td>
                  <td data-label="Celular">
                    <p class="small mt-2 mb-1">
                      {{ item.celular }}
                    </p>
                  </td>
                  <td data-label="Email">
                    <p class="small mt-2 mb-1">
                      <a
                        class="table__email"
                        :href="`mailto:${item.emailContacto}`"
                        >{{ item.emailContacto }}</a
                      >
                    </p>
                  </td>
                  <td data-label="Acciones">
                    <!-- EDITAR -->
                    <router-link
                      :to="{
                        name: 'locales-editar',
                        params: { localId: item.localId },
                      }"
                    >
                      <button
                        type="button"
                        class="btn btn-info mr-2"
                        v-b-tooltip.hover
                        title="Editar Local"
                      >
                        <i class="far fa-edit"></i>
                      </button>
                    </router-link>
                    <!-- ELIMINAR -->
                    <button
                      type="button"
                      class="btn btn-danger"
                      v-b-tooltip.hover
                      title="Eliminar"
                      @click="cambiarEstadoModalEliminar(true, item)"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <p class="text-muted text-center" v-else>
              No existen locales registrados
            </p>
          </div>
        </div>
      </div>
    </section>

    <b-modal id="modal-eliminar-producto" hide-header hide-footer centered>
      <div class="text-center" v-if="itemSeleccionado">
        <p class="my-4">
          ¿Está seguro de eliminar el local
          <span class="font-weight-bold">{{ itemSeleccionado.nomLocal }}</span
          >?
        </p>

        <div class="text-right">
          <button
            type="button"
            class="btn btn-link"
            @click="cambiarEstadoModalEliminar(false)"
          >
            Volver
          </button>

          <button
            type="button"
            class="btn btn-danger"
            :disabled="loadingAccion"
            @click="eliminar()"
          >
            {{ loadingAccion ? "Eliminando..." : "Eliminar" }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
  </Default>
</template>

<script>
import Default from "@/layouts/default.vue";

import GetAllLocales from "@/apollo/queries/locales/GetAllLocales.gql";
import DeleteLocal from "@/apollo/mutations/locales/DeleteLocal.gql";

import Banner from "@/components/global/Banner";
import companyBG from "@/assets/banner/company.jpg";

export default {
  data() {
    return {
      items: [],
      query: null,
      itemSeleccionado: null,
      loadingAccion: false,
      companyBG: companyBG,
    };
  },
  mounted() {
    this.getItems();
  },
  components: {
    Banner, Default
  },
  methods: {
    getItems() {
      this.query = null;

      this.$apollo
        .query({
          query: GetAllLocales,
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.items = response.data.GetAllLocales;
        });
    },
    cambiarEstadoModalEliminar(mostrar, item) {
      if (mostrar) {
        this.itemSeleccionado = item;

        this.$bvModal.show("modal-eliminar-producto");
      } else {
        this.$bvModal.hide("modal-eliminar-producto");

        this.itemSeleccionado = null;
      }
    },
    eliminar() {
      this.loadingAccion = true;

      let localId = parseInt(this.itemSeleccionado.localId);

      this.$apollo
        .mutate({
          mutation: DeleteLocal,
          variables: {
            input: {
              localId,
            },
          },
        })
        .then(() => {
          this.eliminarPorIndice();

          this.loadingAccion = false;

          this.cambiarEstadoModalEliminar(false);

          this.$bvToast.toast("Local eliminado", {
            // title: `Variant ${variant || "default"}`,
            variant: "success",
            solid: true,
          });
        });
    },
    eliminarPorIndice() {
      let index = this.items.indexOf(this.itemSeleccionado);

      this.items.splice(index, 1);

      this.itemSeleccionado = null;
    },
  },
  computed: {
    itemsFiltrados: function() {
      let data = this.items;

      if (this.query) {
        let queryMinuscula = this.query.toLowerCase();

        data = this.items.filter(
          (item) =>
            item.localId.includes(this.query) ||
            item.nomLocal.toLowerCase().includes(queryMinuscula)
        );
      }

      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-add-asignacion {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="scss">
.table {
  &__email {
    text-decoration: none;
    color: #000;
    font-weight: 500;
  }
}
.locales {
  &__image {
    width: 40px;
    height: 40px;

    border-radius: 50%;
  }
}
</style>
